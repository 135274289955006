// 引入axios
import axios from 'axios'
import { successAlert, errorAlert } from '@/utils/alert.js'
import { getToken, removeToken } from './auth.js'
import { MessageBox, Message } from 'element-ui'
// 创建axios实例
const httpService = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '',//api的base_url
    // timeout: 10000 //请求时间
});

// request拦截器
httpService.interceptors.request.use(
    config => {
        config.headers['Authorization'] = getToken()
        return config;
    },
    error => {
        // 请求错误处理
        Promise.reject(error)
    }
)
// respone拦截器
httpService.interceptors.response.use(
    response => {
        //响应拦截的逻辑
        // 统一打印响应数据
        console.group("本次请求地址：" + response.config.url)
        console.log(response);
        console.groupEnd()
        //统一处理成功的情景
        if (response.data.status == 1) {
            return Promise.resolve(response)
        } else if (response.data.code == 200) {
            return Promise.resolve(response)
        } else {

        }
        if (response.data.code == 111) {
            errorAlert(response.data.msg)
        }
        if (response.data.code == 202) {
            removeToken('vue_admin_template_token')
            MessageBox.confirm('登录超时或认证失败，请重新登录', '重新登录', {
                showClose: false,
                distinguishCancelAndClose: true,
                showCancelButton: false,
                closeOnClickModal: false,
                closeOnPressEscape: false,
                confirmButtonText: '重新登录',
                type: 'warning'
            }).then(() => {
                location.reload()
            })
            this.$confirm('登录超时或认证失败，请重新登录', '重新登录', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                location.reload()
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
            // 跳转到登录页

        } else {

        }
        return response;
    },
    // 处理处理
    error => {
        if (error && error.response) {
            switch (error.response.msg) {
                case 400:
                    error.message = '错误请求';
                    break;
                case 401:
                    error.message = '未授权，请重新登录';
                    break;
                case 403:
                    error.message = '拒绝访问';
                    break;
                case 404:
                    error.message = '请求错误,未找到该资源';
                    break;
                case 405:
                    error.message = '请求方法未允许';
                    break;
                case 408:
                    error.message = '请求超时';
                    break;
                case 500:
                    error.message = '服务器端出错';
                    break;
                case 501:
                    error.message = '网络未实现';
                    break;
                case 502:
                    error.message = '网络错误';
                    break;
                case 503:
                    error.message = '服务不可用';
                    break;
                case 504:
                    error.message = '网络超时';
                    break;
                case 505:
                    error.message = 'http版本不支持该请求';
                    break;
                default:
                    error.message = `未知错误${error.response.status}`;
            }
        } else {
            error.message = "连接到服务器失败";
        }
        console.log('error', error)
        return Promise.reject(error);
    }
)

/*网络请求部分*/

/*
 *  get请求
 *  url:请求地址
 *  params:参数
 * */
export function get (url, params = {}, time = 10000) {
    return new Promise((resolve, reject) => {
        httpService({
            url: url,
            method: 'get',
            params: params,
            timeout: time
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

/*
 *  post请求
 *  url:请求地址
 *  params:参数
 * */
export function post (url, params = {}, time = 10000) {
    return new Promise((resolve, reject) => {
        httpService({
            url: url,
            method: 'post',
            data: params,
            timeout: time,
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

/*
 *  文件上传
 *  url:请求地址
 *  params:参数
 * */
export function fileUpload (url, params = {}, time = 10000) {
    return new Promise((resolve, reject) => {
        httpService({
            url: url,
            method: 'post',
            data: params,
            headers: { 'Content-Type': 'multipart/form-data' },
            timeout: time,
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
};

export function exportDetailOrder (url, params = {}, time = 10000) {
    return new Promise((resolve, reject) => {
        httpService({
            url: url,
            method: 'get',
            responseType: 'blob',
            params: params,
            timeout: time,
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}
httpService.get = get
httpService.post = post
httpService.exportDetailOrder = exportDetailOrder
export default httpService