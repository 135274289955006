import Vue from 'vue'
import Vuex from 'vuex'
import tab from './modules/tab.js'
import ksAccount from './modules/ksAccount.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    tab,
    ksAccount
  }
})
